import {
  EuiButton,
  EuiFilePicker,
  EuiForm,
  EuiFormRow,
  EuiPageTemplate,
  EuiProvider,
} from "@elastic/eui";
import React from "react";

import "@elastic/eui/dist/eui_theme_light.css";

const App = () => (
  <EuiProvider colorMode="light">
    <EuiPageTemplate panelled={true} restrictWidth="90%">
      <EuiPageTemplate.Header pageTitle="👁 Matanemia" />

      <EuiPageTemplate.Section>
        <EuiForm>
          <EuiFormRow label="Upload Image">
            <EuiFilePicker />
          </EuiFormRow>

          <EuiFormRow>
            <EuiButton>Predict Anemia</EuiButton>
          </EuiFormRow>
        </EuiForm>
      </EuiPageTemplate.Section>
    </EuiPageTemplate>
  </EuiProvider>
);

export default App;
